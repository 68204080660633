import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import GetClient from './GetClient'

function Home() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [requesting, setRequesting] = useState(false)

  const handleRequest = async (e) => {
    e.preventDefault()
    if (requesting) {
      return
    }
    setRequesting(true)
    if (name && email && subject && message) {
      const newRequest = {
        name,
        email,
        subject,
        message,
      }
      console.log(newRequest)
      toast.success('Your request has been sent successfully')
      setName('')
      setEmail('')
      setSubject('')
      setMessage('')
    } else {
      toast.error('Please fill all the fields')
    }
    setRequesting(false)
  }

  useEffect(() => {
    document.title = 'UHDSS - Education Agency'
    window.scrollTo(0, 0)
  }, [])

  return (
    <main style={{ width: '100%', overflow: 'hidden' }} id="main" className="mt-5">
      <GetClient />
      <section className="px-3">
        <div className="col-md-9 mx-auto">
          <h6 className="w-auto p-3">We are Accredited by:</h6>
          <div className="row">
            <div className="col-md-6 p-3">
              <a href="https://www.icef.com/agency/0010J00002C8NfdQAF" target="blank">
                <img
                  src="https://res.cloudinary.com/dg611cbhp/image/upload/v1713867261/uhdss/icef_log_copy_z6lpsm.png"
                  alt="ICEF Accreditation"
                  className="w-100 p-3"
                />
              </a>
            </div>
            <div className="col-md-6 p-3">
              <video
                id="prevd"
                className="w-100 rounded-lg"
                alt="uhdss_3d_motion_preview"
                autoPlay={true}
                muted={true}
                loop={true}
              >
                <source
                  src="https://res.cloudinary.com/dg611cbhp/video/upload/v1718801467/uhdss/WhatsApp_Video_2024-06-19_at_12.20.24_67a6761e_mjs1fu.mp4"
                  type="video/mp4"
                />
                Your Browser does not support video play
              </video>
            </div>
          </div>
        </div>
        <div className="col-md-9 mx-auto">
          <h6 className="w-auto p-3">We are Certified by:</h6>
          <div className="d-flex justify-content-between align-items-center flex-wrap logos-container">
            <div className="p-1 logo">
              <a
                href="https://res.cloudinary.com/dg611cbhp/image/upload/v1704519898/uhdss/iso2_tjbdoj.jpg"
                target="blank"
              >
                <img
                  src="https://res.cloudinary.com/dg611cbhp/image/upload/v1704613046/uhdss/iso_uclgra.png"
                  alt="ISO Certification"
                  className="w-100 p-3"
                />
              </a>
            </div>
            <div className="p-3 logo">
              <a
                href="https://res.cloudinary.com/dg611cbhp/image/upload/v1704519895/uhdss/british2_xi0ddq.jpg"
                target="blank"
              >
                <img
                  src="https://res.cloudinary.com/dg611cbhp/image/upload/v1704613046/uhdss/british_smqeaj.png"
                  alt="British Council"
                  className="w-100 p-3"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-9 mx-auto">
          <div className="embed-responsive embed-responsive-4by3">
            <video
              id="prevd"
              className="w-100 rounded-lg"
              alt="uhdss_3d_motion_preview"
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source
                src="https://res.cloudinary.com/dg611cbhp/video/upload/v1680035826/3D_Explainer_Video_Toolkit_720p_1_y7zquc.mp4"
                type="video/mp4"
              />
              Your Browser does not support video play
            </video>
          </div>
        </div>
      </section>

      <section className="pt-4 pt-md-11 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-right">
              <h1 className="display-3 text-center my-4 text-md-start dark-blue">
                Welcome to Africa&aposs best Education Agency.
              </h1>

              <p className="lead text-start lh-base dark-blue fs-4 lh-sm mb-6 mb-lg-8">
                UHDSS education agency is Africa&aposs best education agency for students who want to study abroad,
                Immigration services, Visiting VISA, Business VISA, Tourist VISA and other Tourism Services.
              </p>
            </div>

            <div className="col-12 col-md-5 col-lg-6 order-md-2" data-aos="fade-left">
              <img
                src="/vectors/splash.jpg"
                className="mx-auto img-fluid mw-md-150 rounded-lg mw-lg-130 mb-6 mb-md-0 aos-init aos-animate d-block"
                alt="..."
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </div>
          </div>
          <div className="col-sm-10 mx-auto pt-3" data-aos="fade-up">
            <p className="lead text-start dark-blue fs-5 p-lh mb-6 mb-lg-8 mt-2">
              We strongly work with countries like: Canada, USA, Europe, Asia, South America, and Africa. We help our
              applicants regardless of where they come from (Country). Our service is very quick and reliable, and we
              have more than 5 years of experience in this field. Our work has a success rate of more than 98% in VISA
              success and 90% in other services. We have already helped applicants from different continents. We also
              accept agents who are interested in working with us.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-0 pb-0 mt-0 pt-5" data-aos="flip-up">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-9 col-xl-8">
              <h1 className="display-6 text-center dark-blue">Study or work in the country of your choice.</h1>

              <p className="lead mb-7 mt-5 text-center dark-blue">
                If you are looking for a university where you can study and work after finishing your studies, you are
                in the right place. After helping our applicants get good schools and VISAs, we also help them find jobs
                in their study country.
              </p>
            </div>
          </div>

          <div className="mt-5 col-md-7 mx-auto">
            <img src="/vectors/sitreas.jpg" className="img-fluid rounded-lg mx-auto d-block" alt="..." />
          </div>
        </div>
      </section>

      <section className="pt-0 pb-5 pb-md-11" data-aos="zoom-in-up">
        <div className="container py-5 px-lg-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-5 display-6 dark-blue">Get In Touch!</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                <form className="py-4 px-3 rounded shadow" onSubmit={handleRequest}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          className="form-control"
                          id="name"
                          required={true}
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          id="email"
                          required={true}
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSubject(e.target.value)}
                          value={subject}
                          id="subject"
                          required={true}
                          placeholder="Subject"
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                          placeholder="Leave a message here"
                          id="message"
                          required={true}
                          style={{ height: '200px' }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn bg-primary rounded-pill py-3 px-5"
                        disabled={requesting}
                        type="submit"
                      >
                        {requesting ? 'Sending Message . . .' : 'Send Message'}
                      </button>
                    </div>
                  </div>
                </form>
                <h3 className='text-center mt-3'>We are member of </h3>
                <div className="row mt-4">
                  <div className="col-6">
                    <img
                      src="https://res.cloudinary.com/dg611cbhp/image/upload/v1719255310/uhdss/ict_chamber_qy6ama.png"
                      className="img-fluid rounded-lg"
                      alt="Contact Us Image 1"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src="https://res.cloudinary.com/dg611cbhp/image/upload/v1719255311/uhdss/rata_nagjnv.png"
                      className="img-fluid rounded-lg"
                      alt="Contact Us Image 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home
